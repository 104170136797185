import React, { Component } from "react";
import PropTypes from "prop-types";
import * as THREE from "three";
import { OBJLoader } from "three-obj-mtl-loader";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { AppContext } from "~context/AppContext";
import { fancyWarning } from "~utils/helpers";

OBJLoader(THREE);

class ThreeCanvasComponent extends Component {
  loaded = false;

  threeCanvasRef = React.createRef();

  //

  loadingManager;

  objLoader;

  textureLoader;

  //

  boundingBox;

  center;

  gltfLoader;

  modelId;

  object;

  size;

  THREE;

  //

  multipleObjects = [];

  //

  componentDidMount() {
    this.THREE = THREE;
  }

  componentWillUnmount() {
    this.stop();

    if (this.threeCanvasRef.current) {
      this.threeCanvasRef.current.removeChild(this.renderer.domElement);
    }
  }

  //

  load = () => {
    const { loadedObjects } = this.props.appContext;

    if (loadedObjects.includes(this.props.modelName) || !this.props.modelName) {
      fancyWarning(`duplicate initialisation`);
      // return;
    }

    this.loaded = true;

    const width = this.threeCanvasRef.current.clientWidth;
    const height = this.threeCanvasRef.current.clientHeight;

    this.camera = new this.THREE.PerspectiveCamera(
      30,
      width / height,
      0.1,
      1000
    );

    this.renderer = new this.THREE.WebGLRenderer({
      alpha: true,
      antialias: true,
      shadowMapEnabled: true
    });

    this.renderer.setClearColor(0x000000, 0);
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(width, height);

    this.scene = new this.THREE.Scene();

    this.threeCanvasRef.current.appendChild(this.renderer.domElement);

    //

    this.loadingManager = new this.THREE.LoadingManager();

    //

    const assetPath = `/webgl/${this.props.modelName}`;
    const textureImage = `${assetPath}/texture.jpg`;

    switch (this.props.modelName) {
      case `black-friday`:
        this.camera.position.x = -25;
        this.camera.position.y = 15;
        this.camera.position.z = 40;

        this.camera.rotation.z += -0.25;

        this.loadObjectWithMaterial();

        break;

      case `bronzing-powder`:
        this.camera.position.x = 0;
        this.camera.position.y = 0;
        this.camera.position.z = 300;

        this.getMaterialFromTexture(
          textureImage,
          THREE.SphericalReflectionMapping
        ).then(loadedMaterial => {
          this.loadObject(assetPath, loadedMaterial);
        });

        break;

      case `kabuki-brush`:
        this.camera.position.x = 0;
        this.camera.position.y = 0;
        this.camera.position.z = 40;

        this.getMaterialFromTexture(
          textureImage,
          THREE.SphericalReflectionMapping
        ).then(loadedMaterial => {
          this.loadObject(assetPath, loadedMaterial);
        });

        break;

      case `face-oil`:
        this.camera.position.x = 0;
        this.camera.position.y = 5;
        this.camera.position.z = 40;

        this.loadTexturedObject(assetPath);

        break;

      case `lip-oil`:
        this.camera.position.x = 0;
        this.camera.position.y = 40;
        this.camera.position.z = 400;

        this.loadGlb();

        break;

      default:
        break;
    }
  };

  //
  // loaders

  getMaterialFromTexture = (url, mapping) => {
    if (!this.textureLoader) {
      this.textureLoader = new this.THREE.TextureLoader(this.loadingManager);
    }

    return new Promise((resolve, reject) => {
      this.textureLoader.load(url, texture => {
        texture.mapping = mapping;

        const texturedMaterial = new this.THREE.MeshBasicMaterial({
          envMap: texture
        });

        if (!texturedMaterial) {
          reject(new Error(`Material could not be created`));
        } else {
          resolve(texturedMaterial);
        }
      });
    });
  };

  loadObject = (path, material) => {
    if (!this.objLoader) {
      this.objLoader = new this.THREE.OBJLoader();
    }

    this.objLoader.load(`${path}/model.obj`, object => {
      this.object = object;

      const { loadedObjects } = this.props.appContext;

      loadedObjects.push(this.props.modelName);

      this.props.appContext.setLoadedObjects(loadedObjects);

      this.object.traverse(node => {
        if (node.isMesh && material) {
          node.material = material;
        }
      });

      this.scene.add(this.object);

      this.start();
    });
  };

  //

  loadGlb = () => {
    const rotations = {
      x: 35 * (Math.PI / 180),
      y: 35 * (Math.PI / 180),
      z: 0 * (Math.PI / 180)
    };

    const ambientLight = new this.THREE.AmbientLight(0xcccccc, 1);
    const directionalLight = new this.THREE.DirectionalLight(0xffffff);
    directionalLight.position.set(10, 10, 1).normalize();

    this.scene.add(directionalLight);
    this.scene.add(ambientLight);

    this.gltfLoader = new GLTFLoader();

    this.gltfLoader.load(
      `/webgl/${this.props.modelName}-clear-top/lip-oil-clear-top.glb`,
      gltf => {
        gltf.scene.scale.set(1, 1, 1);
        gltf.scene.position.x = 0;
        gltf.scene.position.y = 0;
        gltf.scene.position.z = 0;

        gltf.scene.rotation.x = rotations.x;
        gltf.scene.rotation.y = rotations.y;
        gltf.scene.rotation.z = rotations.z;

        if (!this.textureLoader) {
          this.textureLoader = new this.THREE.TextureLoader(
            this.loadingManager
          );
        }

        return new Promise((resolve, reject) => {
          this.textureLoader.load(
            `/webgl/${this.props.modelName}-clear-top/texture.jpg`,
            texture => {
              texture.mapping = this.THREE.SphericalReflectionMapping;

              const texturedMaterial = new this.THREE.MeshBasicMaterial({
                envMap: texture
              });

              if (!texturedMaterial) {
                reject(new Error(`Material could not be created`));
              } else {
                resolve(texturedMaterial);
              }
            }
          );
        }).then(material => {
          gltf.scene.traverse(node => {
            if (node.isMesh && material) {
              node.material = material;
              // node.castShadow = true;

              this.multipleObjects.push(gltf.scene);

              this.scene.add(gltf.scene);

              this.start();
            }
          });
        });
      }
    );

    this.gltfLoader.load(
      `/webgl/${this.props.modelName}-clear-bottom/lip-oil-clear-bottom.glb`,
      gltf => {
        gltf.scene.scale.set(1, 1, 1);
        gltf.scene.position.x = -9;
        gltf.scene.position.y = 0;
        gltf.scene.position.z = 0;

        gltf.scene.rotation.x = rotations.x;
        gltf.scene.rotation.y = rotations.y;
        gltf.scene.rotation.z = rotations.z;

        if (!this.textureLoader) {
          this.textureLoader = new this.THREE.TextureLoader(
            this.loadingManager
          );
        }

        return new Promise((resolve, reject) => {
          this.textureLoader.load(
            `/webgl/${this.props.modelName}-clear-bottom/texture.jpg`,
            texture => {
              texture.mapping = this.THREE.SphericalReflectionMapping;

              const texturedMaterial = new this.THREE.MeshBasicMaterial({
                envMap: texture
              });

              if (!texturedMaterial) {
                reject(new Error(`Material could not be created`));
              } else {
                resolve(texturedMaterial);
              }
            }
          );
        }).then(material => {
          gltf.scene.traverse(node => {
            if (node.isMesh && material) {
              node.material = material;

              this.multipleObjects.push(gltf.scene);

              this.scene.add(gltf.scene);

              this.start();
            }
          });
        });
      }
    );
  };

  //

  loadTexturedObject = path => {
    if (!this.gltfLoader) {
      this.gltfLoader = new GLTFLoader();
    }

    this.gltfLoader.load(`${path}/hero-asset.gltf`, gltf => {
      gltf.scene.scale.set(100, 100, 100);

      this.object = gltf.scene;

      const ambientLight = new this.THREE.AmbientLight(0xcccccc, 1);
      const directionalLight = new this.THREE.DirectionalLight(0xffffff);
      directionalLight.position.set(0, 1, 1).normalize();

      this.scene.add(directionalLight);
      this.scene.add(ambientLight);
      this.scene.add(this.object);
    });

    this.start();
  };

  //
  // rendering

  renderScene = () => {
    this.renderer.render(this.scene, this.camera);
  };

  animate = () => {
    if (!this.multipleObjects.length) {
      return;
    }

    const { appContext } = this.props;

    this.multipleObjects.forEach(object => {
      if (object.position.x === -9) {
        object.rotation.z = -appContext.scrollTop / 300;
      } else {
        object.rotation.z = appContext.cursorCenterDeltaX;
      }
      object.rotation.x = -appContext.scrollTop / 400;
    });

    this.renderScene();

    this.frameId = window.requestAnimationFrame(this.animate);
  };

  start = () => {
    if (!this.frameId) {
      this.frameId = requestAnimationFrame(this.animate);
    }
  };

  stop = () => {
    cancelAnimationFrame(this.frameId);
  };

  render() {
    if (!this.loaded && this.props.ready && this.threeCanvasRef.current) {
      this.loaded = true;

      this.load();
    }

    return (
      <div
        ref={this.threeCanvasRef}
        className={`three-canvas w-full h-full relative ${
          this.props.className ? this.props.className : ``
        }`}
      ></div>
    );
  }
}

ThreeCanvasComponent.defaultProps = {
  appContext: {
    cursorCenterDeltaX: 0,
    cursorCenterDeltaY: 0,
    loadedObjects: []
  },
  className: ``,
  modelName: ``,
  ready: false
};

ThreeCanvasComponent.propTypes = {
  appContext: PropTypes.shape({
    cursorCenterDeltaX: PropTypes.number,
    cursorCenterDeltaY: PropTypes.number,
    loadedObjects: PropTypes.arrayOf(PropTypes.string),
    setLoadedObjects: PropTypes.func.isRequired,
    scrollTop: PropTypes.number.isRequired
  }),
  className: PropTypes.string,
  modelName: PropTypes.string,
  ready: PropTypes.bool
};

//

const ThreeCanvas = props => (
  <AppContext.Consumer>
    {appContext => <ThreeCanvasComponent appContext={appContext} {...props} />}
  </AppContext.Consumer>
);

//

export default ThreeCanvas;
