/* eslint-disable react/prop-types */

import React, { Component } from "react";
import { AppContext } from "~context/AppContext";

import ThreeCanvas from "~components/ThreeCanvas";

class ThreeJsPlaygroundComponent extends Component {
  componentDidMount() {
    const { appContext } = this.props;

    appContext.setNavText(`Seb's playground`);
  }

  //

  render() {
    return (
      <>
        <section className="w-full h-screen bg-light-pink f1 text-black">
          <h1 className="f1 p-4">ThreeJS Playground</h1>

          <ThreeCanvas modelName="lip-oil" ready />
        </section>

        <section className="w-full h-screen bg-light-royal-blue"></section>
      </>
    );
  }
}

const ThreeJsPlayground = props => {
  return (
    <AppContext.Consumer>
      {appContext => (
        <ThreeJsPlaygroundComponent appContext={appContext} {...props} />
      )}
    </AppContext.Consumer>
  );
};

export default ThreeJsPlayground;
